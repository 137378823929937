import {useContext, createContext, useState, useEffect} from "react";
import { loadPersistedData, persistData } from "../utils/persistor";
import i18next from "i18next";
import cookieService from "../services/cookie";
import {rtlLanguages} from "../constants/common";
import {useWindowSize} from "../hooks/common/useWindowSize";



const context = createContext({
    main: {
        theme: "dark", setTheme: () => { },
        lang: "en", setLang: () => { }
    },
    profile: {
        token: null, setToken: () => { }
    }
})

export const useMainContext = () => {
    return useContext(context)
}

export const SIDEBAR = 240

const MainContextProvider = ({ children }) => {

    const { width } = useWindowSize()
    const [theme, setTheme] = useState(loadPersistedData('theme') ? loadPersistedData('theme') : "dark")
    const [lang, setLang] = useState(loadPersistedData('lang') ? loadPersistedData('lang') : "en")
    const [token, _setToken] = useState(cookieService.get('token'))
    const [refreshToken, _setRefreshToken] = useState(cookieService.get('refresh-token'))
    const [sidebar, setSidebar] = useState(SIDEBAR)
    const [isRtl, setRtl] = useState(false)

    useEffect(() => {
		i18next.changeLanguage(lang)
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (theme === 'dark') {
			document.documentElement.classList.add('dark')
		} else {
			document.documentElement.classList.remove('dark')
		}
	}, [theme])

    useEffect(() => {
        // setSidebar(width < MOBILE_SIZE ? 'mobile' : (
        //     width < TABLET_SIZE ? 'tablet'
        //         : 'desktop'
        // ))
    }, [width]);

    useEffect(() => {
        i18next.changeLanguage(lang)
        persistData('lang', lang)
        setRtl(rtlLanguages.includes(lang))
    }, [lang])

    const setToken = (value) => {
        cookieService.set('token', value, { path: '/'})
        _setToken(value)
    }

    const setRefreshToken = (value) => {
        cookieService.set('refresh-token', value, { path: '/' })
        _setRefreshToken(value)
    }

    const toggleSidebar = () => {
        setSidebar(state => state === SIDEBAR ? 92 : SIDEBAR)
    }

    const toggleTheme = () => {
        const newTheme = theme === 'dark' ? 'light' : 'dark'
        setTheme(newTheme)
        persistData('theme', newTheme)
    }

    return (
        <context.Provider value={{
            theme, toggleTheme,
            lang, setLang,
            token, setToken,
            refreshToken, setRefreshToken,
            sidebar, toggleSidebar,
            isRtl
        }}>
            {children}
        </context.Provider>
    )
}

export default MainContextProvider;
