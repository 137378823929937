import Cookies from "universal-cookie/es6";


const cookie = new Cookies()

const cookieService = {

    get(key) {
        return cookie.get(key)
    },

    set(key, value, options) {
      cookie.set(key, value, options)
    },

    delete(key) {
        return cookie.remove(key,  { path: '/' })
    }
}

export default cookieService
