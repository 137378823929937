import axios from "../../utils/axiosInstanse"
import {BASE_URL} from "../../constants/urls";


/**
 * Put api with token
 * @param {Object} data
 * @param {string} path
 * @returns {Promise}
 */
const putApi = (data, path, throwError) => {
    const resp = axios.put(
        BASE_URL + path, data
    )

    resp.catch(err => {
        if ((err?.response?.status >= 500 || !err?.response?.status) && throwError) {
            throwError(new Error(err))
        }
    })
    return resp
}


export {
    putApi
}
