import { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
} from "react-router-dom"
import GlobalLoading from "../design-system/loading/GlobalLoading";

const Home = lazy(() => {
    return Promise.all([
        import("./Home"),
        new Promise(resolve => setTimeout(resolve, 3000))
    ])
        .then(([moduleExports]) => moduleExports);
});

const Index = () => {

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Suspense fallback={<GlobalLoading />}>
                <Home />
            </Suspense>
        </Router>
    )
}



export default Index
