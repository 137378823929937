import {ReactComponent as AnimLogo} from "../../assets/images/logo/logo-motion.svg"
import {useEffect} from "react";

const GlobalLoading = () => {



    useEffect(() => {
        (function(s,i,u,o,c,w,d,t,n,x,e,p,a,b){(a=Array.from(d.querySelectorAll('svg#' + i.root)).filter(n=> !n.svgatorPlayer)[0]||{}).svgatorPlayer={ready:(function(a){b=[];return function(c){return c?(b.push(c),a.svgatorPlayer):b}})(a)};w[o]=w[o]||{};w[o][s]=w[o][s]||[];w[o][s].push(i);e=d.createElementNS(n,t);e.async=true;e.setAttributeNS(x,'href',[u,s,'.','j','s','?','v','=',c].join(''));e.setAttributeNS(null,'src',[u,s,'.','j','s','?','v','=',c].join(''));p=d.getElementsByTagName(t)[0];p.parentNode.insertBefore(e,p);})('91c80d77',{"root":"eblPmJDFvRk1","version":"2022-05-04","animations":[{"elements":{"eblPmJDFvRk4":{"fill-opacity":[{"t":1300,"v":0,"e":[0.175,0.885,0.32,1.275]},{"t":3000,"v":1,"e":[0.965,-0.035,0.865,0.33]}]},"eblPmJDFvRk5":{"fill-opacity":[{"t":1300,"v":0,"e":[0.175,0.885,0.32,1.275]},{"t":3000,"v":1,"e":[0.965,-0.035,0.865,0.33]}],"stroke-opacity":[{"t":1900,"v":1},{"t":3000,"v":0}],"stroke-dashoffset":[{"t":0,"v":111.505089},{"t":1400,"v":0}]},"eblPmJDFvRk6":{"fill-opacity":[{"t":1300,"v":0,"e":[0.175,0.885,0.32,1.275]},{"t":3000,"v":1,"e":[0.965,-0.035,0.865,0.33]}],"stroke-opacity":[{"t":1900,"v":1},{"t":3000,"v":0}],"stroke-dashoffset":[{"t":0,"v":322.985551},{"t":1400,"v":0}]}},"s":"MDWA2MGQyRzc5YCmJjY2M5YjhjVYmMwYzZjNTcI5OTE4YTg4ODFc4NzgzNzliYQmMwYzliY2JhYY2JjMGM2YzUE3OTkxVzg4ODNM3OVNjMGNiYFmNjOWI4Y2JjJMGM2YzVjYTcW5OTE4NzgzNzDliZEljMGMzYOzM3OTkxODg4VMzc5TmI4YzNRjYlZiY2M5YzTViOGNiYmM3OITkxYmRiOGMzRY2FiYzgzNzlQjYWM3YmNWYmTNiYjc5OTFXOJDc4NThlUjhkOODM3OWJkYzdPjYTc5OTE4ODSg3TDg3ZDQ/"}],"options":"MDLAxMDgyTTI5NT2E3YjY4Nzk3HYjI5NDEyOTcJzNzY2ODZiMjOk4NA|"},'https://cdn.svgator.com/ply/','__SVGATOR_PLAYER__','2022-05-04',window,document,'script','http://www.w3.org/2000/svg','http://www.w3.org/1999/xlink')
    }, [])

    return (
        <div className={'w-screen h-screen dark:bg-d-background bg-l-background flex justify-center items-center'}>
            <AnimLogo
                width={480}
            />
        </div>
    )
}

export default GlobalLoading;
