import {useMutation, useQuery} from "react-query"
import { useMainContext } from "../../../context/main"
import { normalFetch } from "../../fetch-api/get"
import {postApi} from "../../fetch-api/post";
import {putApi} from "../../fetch-api/put"
import useAsyncError from "../../../hooks/common/useAsyncError";
import {useQueryContext} from "../../../context/query";
import {useStore} from "../../../context/store";



export const authKeys = {
    profile: 'profile',
    login: 'login',
    loginComplete: 'loginComplete',
    register: 'register',
    registerComplete: 'registerComplete',
    resetPassword: 'resetPassword',
    resetPasswordComplete: 'resetPasswordComplete',
    ga: 'ga',
    activateGa: 'activateGa',
    towFactor: 'towFactor',
    updateProfile: 'updateProfile'
}

export const useProfile = () => {

    const { token, setToken, setRefreshToken } = useMainContext()

    return useQuery(
        authKeys.profile, () => normalFetch('users/self'),
        {
            enabled: !!token,
            select: res => res?.data?.data,
            refetchInterval: 30 * 1000,
            onError: () => {
                setToken('')
                setRefreshToken('')
            }
        }
    )
}

export const useLogin = (onSuccess) => {

    const throwError = useAsyncError()

    return useMutation(
        authKeys.login, (payload) => postApi(payload, 'auth/login', throwError),
        {
            onSuccess
        }
    )
}

export const useLoginComplete = (onSuccess) => {

    const throwError = useAsyncError()

    return useMutation(
        authKeys.loginComplete, (payload) => postApi(payload, 'auth/login/complete', throwError),
        {
            onSuccess
        }
    )
}

export const useRegister = (onSuccess) => {

    const throwError = useAsyncError()

    return useMutation(
        authKeys.register, (payload) => postApi(payload, 'auth/register', throwError),
        {
            onSuccess
        }
    )
}


export const useRegisterComplete = (onSuccess) => {

    const throwError = useAsyncError()

    return useMutation(
        authKeys.registerComplete, (payload) => postApi(payload, 'auth/register/complete', throwError),
        {
            onSuccess
        }
    )
}


export const useResetPassword = (onSuccess) => {

    const throwError = useAsyncError()

    return useMutation(
        authKeys.resetPassword, (payload) => postApi(payload, 'auth/forgetPassword', throwError),
        {
            onSuccess
        }
    )
}

export const useResetPasswordComplete = (onSuccess) => {

    const throwError = useAsyncError()

    return useMutation(
        authKeys.resetPasswordComplete, (payload) => postApi(payload, 'auth/forgetPassword/complete', throwError),
        {
            onSuccess
        }
    )
}

export const useLogout = (onSuccess) => {

    const throwError = useAsyncError()
    const { setToast } = useQueryContext()
    return useMutation(
        authKeys.resetPasswordComplete, (payload) => postApi(payload, 'auth/logout', throwError),
        {
            onSuccess: () => {
                setToast({
                    show: true, message: 'logout-success'
                })
                onSuccess()
            }
        }
    )
}

export const useGa = (onSuccess) => {

    const throwError = useAsyncError()
    return useMutation(
        authKeys.ga, () => postApi(null, 'users/self/ga', throwError),
        {
            onSuccess
        }
    )
}

export const useActivateGa = (onSuccess) => {

    const throwError = useAsyncError()
    const { setToast } = useQueryContext()
    return useMutation(
        authKeys.activateGa, (payload) => putApi(payload, 'users/self/ga', throwError),
        {
            onSuccess: () => {
                setToast({
                    show: true, message: 'activate-ga-success'
                })
                onSuccess()
            }
        }
    )
}


export const useRequestTwoFactor = (onSuccess) => {

    const throwError = useAsyncError()
    const { setToast } = useQueryContext()

    return useMutation(
        authKeys.towFactor, () => postApi({}, 'users/self/twoFactor', throwError),
        {
            onSuccess: () => {
                setToast({
                    show: true, message: 'otp-sent-success'
                })
                onSuccess()
            }
        }
    )
}


export const useUpdateProfile = () => {

    const { refetchProfile } = useStore()
    const throwError = useAsyncError()
    const { setToast } = useQueryContext()


    return useMutation(
        authKeys.updateProfile, (payload) => putApi(payload, 'users/self', throwError),
        {
            onSuccess: () => {
                setToast({
                    show: true, message: 'update-profile-success'
                })
                refetchProfile()
            }
        }
    )
}
