/**
 *
 * @param {string} theme
 * @param {string} lang
 * @return {Object}
 */
const getMainTheme = (theme = 'dark', lang = 'en') => {

    const MainTheme = {
        dark: {
            mainBg: "#0A141D",
            toastBg: "#292A34",
            mainRed: "#FF5740",
            mainGreen: "#00A693",
            hover: 'rgba(255, 255, 255, 0.08)',
            reverse: 'white',
            secondaryBg: '#091C2D',
            thirdBg: '#0C1823',
            navBg: '#00324E',
            active: '#197F64',
            active2: '#0E5044',
            inActiveText: '#fafafa40',
            shadow: '#00000080',
            skeleton: "#0A0C17",
            shimmer: "#0F1324"

        },
        light: {
            mainBg: "white",
            toastBg: "#fafafa",
            mainRed: "#FF5740",
            mainGreen: "#00A693",
            hover: '#00000010',
            reverse: '#0A0C17',
            secondaryBg: '#fafafa',
            thirdBg: '#f3f3f3',
            navBg: '#00324E',
            active: '#197F64',
            active2: '#0E5044',
            inActiveText: '#00000070',
            shadow: '#435a6fb3',
            skeleton: "#ddd",
            shimmer: "rgba(255,255,255, 0.2)",

        }
    };

    const chosenTheme = { ...MainTheme[theme] }
    chosenTheme.english = lang === "en"
    return chosenTheme
}


const fadeVariants = {
    out: {
        opacity: 0, transition: { duration: 0.3 }
    },
    in: {
        opacity: 1, transition: { duration: 0.3 }
    }
}


const getRandomColor = (idx) => {
    const randomColor = [
        '#818F9F', '#9F819E', '#9F9181', '#819F82'
    ]
    return randomColor[idx % 3]
}

const getUserTagColors = (idx, theme) => {
    const colors = [
        { dark: '#4487c990', light: '#70c8ff90' },
        { dark: '#2db19190', light: '#67ccb490' },
        { dark: '#f5fcaa90', light: '#f5fcaa90' },
        { dark: '#e7717d90', light: '#ff738190' },
    ]
    return colors[idx % 4][theme]
}

const getTradeTypeColor = (type) => {
    const typedColors = {
        oco: '#819F82',
        stop: '#9F819E',
        market: '#9F9181',
        limit: '#818F9F',
        pending: '#9F9181',
        success: '#819F82',
        error: '#9F819E',
        canceled: '#9F9181',
        timeout: '#9F819E',
        order: '#819F82',
        withdraw: '#9F9181',
        deposit: '#818F9F',
        otc: '#9F9181',
        buy: '#819F82',
        sell: '#9F819E',
        banking: '#9F819E',
        coining: '#9F819E',
        maker: '#819F82',
        taker: '#9F9181',
        completed: '#819F82',
        increase: '#819F82',
        decrease: '#9F819E',
        rejected: '#9F819E',
        done: '#819F82',
        day: '#9F9181',
        month: '#9F819E',
        week: '#818F9F',
        total: '#819F82',
        admin: '#819F82',
        user: '#9F819E',
        individual: '#818F9F',
        waitingForApprove: '#9F9181'
    }

    return typedColors[type]
}


export {
    getMainTheme,
    fadeVariants,
    getRandomColor,
    getTradeTypeColor,
    getUserTagColors
}
