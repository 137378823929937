/**
 * load local storage persisted data
 * @param {string} key
 * @return {null|any}
 */
import cookieService from "../services/cookie";

export const loadPersistedData = (key) => {
    try {
        return cookieService.get(key)

    } catch (err) {
        return null
    }
}

/**
 * persist data in local storage
 * @param {string} key
 * @param {any} state
 * @return {void}
 */
export const persistData = (key, state) => {
    try {
        cookieService.set(key, state)
    } catch (err) {
        // ignore
    }
}

