const MOBILE_SIZE = 768
const TABLET_SIZE = 1050
const SIDEBAR = 240
const SIZE = 24
const MSIZE = 18
const IOSLandscape = 748

// const validEmailRegex =
//     /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const validEmailRegex = /\S+@\S+\.\S+/

const RECAPTCHA_KEY = '6LfXxZ8gAAAAAMhnhblsXyNnWI-ulVl7w2_DxHaU'

const rtlLanguages = [
    'fa'
]

const staticTimes = [
    'instant', '1-day', '1-week', '1-month'
]

const allFonts = {
    'en': 'Inter',
    'fa': 'dana'
}


export {
    MOBILE_SIZE,
    TABLET_SIZE,
    SIDEBAR,
    validEmailRegex,
    RECAPTCHA_KEY,
    SIZE,
    MSIZE,
    rtlLanguages,
    staticTimes,
    IOSLandscape,
    allFonts
}
